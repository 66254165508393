import Group from "@/models/group";
import ApiService from "@/services/api.service";
import { ApiResponse } from "@/types/ApiResponse";

const GroupService = {
    store: async (data: object, type = 'soccer') => {
        return ApiService.put(`/groups/${type}`, data);
    },

    find: async (id: string) => ApiService.get('groups/' + id)
        .then((response) => new Group(response.data))
        .catch((error) => {
            throw error;
        }),

    openShareLink: (shareLink: string) => ApiService.post(
        '/group/share',
        { shareLink },
        undefined) as Promise<ApiResponse<Group>>
    ,

    getPouleNameByShareLink: (shareLink: string) =>
        ApiService.get(`group/share/name/${shareLink}`)
            .then((response) => response.data.name),

    index: async () => {
        const response = await ApiService.get('/groups');

        return response.data.map((group: Group) => new Group(group));
    },

    loadMatches: async (id: string, page: number, onlyPlayed: boolean) => {
        const params = {
            page,
            played: onlyPlayed ? true : undefined,
            perPage: 7,
        };

        if (onlyPlayed) {
            params.played = true;
        }

        return ApiService.get(`/group/${id}/matches`, params)
            .then((response) => response.data).catch((error) => {
                throw error;
            });
    },
    availableTournaments: async () => {
        const response = await ApiService.get('/seasons');

        return response.data.data;
    },
    leave: async (id: string) => {
        return ApiService.delete(`/groups/${id}/remove/me`);
    },
}

export default GroupService;
